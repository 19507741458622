<template lang="">
    <div>
        <AbilityEditor :docid="$route.params.docid"  />
    </div>
</template>
<script>
import AbilityEditor from '@/components/AbilityEditor'
export default {
    components: {
        AbilityEditor
    }
    
}
</script>
<style lang="">
    
</style>